import { SearchDocumentType, ApiTypes } from '@wix/client-search-sdk';
import { NotTranslatedDropdownOption } from '../SearchResultsControllerStore.types';

const enum SortOptionId {
  Default = -1,
  ForumRelevance = 0,
  ForumLastActivityDateDesc = 1,
  ForumLastActivityDateAsc = 2,
  ForumLikeCountDesc = 3,
  ForumLikeCountAsc = 4,
  ForumTotalCommentsDesc = 5,
  ForumTotalCommentsAsc = 6,
  EventsRelevance = 7,
  EventsStartDateDesc = 8,
  EventsStartDateAsc = 9,
  EventsMinPriceDesc = 10,
  EventsMinPriceAsc = 11,
  EventsMaxPriceDesc = 12,
  EventsMaxPriceAsc = 13,
}

const enum ForumOrderingFields {
  lastActivityDate = 'lastActivityDate',
  likeCount = 'likeCount',
  totalComments = 'totalComments',
}

const enum EventsOrderingFields {
  startDate = 'startDate',
  minPrice = 'minPrice',
  maxPrice = 'maxPrice',
}

const enum OrderingDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

const sortOptionConfig: {
  [key in SortOptionId]: {
    translation: string;
    sorters: ApiTypes.ISearchRequestSorters[];
  };
} = {
  [SortOptionId.Default]: {
    translation: 'searchResults.sort.options.default',
    sorters: [],
  },
  [SortOptionId.ForumRelevance]: {
    translation: 'searchResults.sort.options.forum.default',
    sorters: [],
  },
  [SortOptionId.ForumLastActivityDateDesc]: {
    translation: 'searchResults.sort.options.forum.lastActivityDate.desc',
    sorters: [
      {
        fieldName: ForumOrderingFields.lastActivityDate,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.ForumLastActivityDateAsc]: {
    translation: 'searchResults.sort.options.forum.lastActivityDate.asc',
    sorters: [
      {
        fieldName: ForumOrderingFields.lastActivityDate,
        direction: OrderingDirection.ASC,
      },
    ],
  },
  [SortOptionId.ForumLikeCountDesc]: {
    translation: 'searchResults.sort.options.forum.likeCount.desc',
    sorters: [
      {
        fieldName: ForumOrderingFields.likeCount,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.ForumLikeCountAsc]: {
    translation: 'searchResults.sort.options.forum.likeCount.asc',
    sorters: [
      {
        fieldName: ForumOrderingFields.likeCount,
        direction: OrderingDirection.ASC,
      },
    ],
  },
  [SortOptionId.ForumTotalCommentsDesc]: {
    translation: 'searchResults.sort.options.forum.totalComments.desc',
    sorters: [
      {
        fieldName: ForumOrderingFields.totalComments,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.ForumTotalCommentsAsc]: {
    translation: 'searchResults.sort.options.forum.totalComments.asc',
    sorters: [
      {
        fieldName: ForumOrderingFields.totalComments,
        direction: OrderingDirection.ASC,
      },
    ],
  },
  [SortOptionId.EventsRelevance]: {
    translation: 'searchResults.sort.options.events.default',
    sorters: [],
  },
  [SortOptionId.EventsStartDateDesc]: {
    translation: 'searchResults.sort.options.events.startDate.desc',
    sorters: [
      {
        fieldName: EventsOrderingFields.startDate,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.EventsStartDateAsc]: {
    translation: 'searchResults.sort.options.events.startDate.asc',
    sorters: [
      {
        fieldName: EventsOrderingFields.startDate,
        direction: OrderingDirection.ASC,
      },
    ],
  },
  [SortOptionId.EventsMinPriceDesc]: {
    translation: 'searchResults.sort.options.events.minPrice.desc',
    sorters: [
      {
        fieldName: EventsOrderingFields.minPrice,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.EventsMinPriceAsc]: {
    translation: 'searchResults.sort.options.events.minPrice.asc',
    sorters: [
      {
        fieldName: EventsOrderingFields.minPrice,
        direction: OrderingDirection.ASC,
      },
    ],
  },
  [SortOptionId.EventsMaxPriceDesc]: {
    translation: 'searchResults.sort.options.events.maxPrice.desc',
    sorters: [
      {
        fieldName: EventsOrderingFields.maxPrice,
        direction: OrderingDirection.DESC,
      },
    ],
  },
  [SortOptionId.EventsMaxPriceAsc]: {
    translation: 'searchResults.sort.options.events.maxPrice.asc',
    sorters: [
      {
        fieldName: EventsOrderingFields.maxPrice,
        direction: OrderingDirection.ASC,
      },
    ],
  },
};

const forumSortOptions = [
  SortOptionId.ForumRelevance,
  SortOptionId.ForumLastActivityDateDesc,
  SortOptionId.ForumLikeCountDesc,
  SortOptionId.ForumTotalCommentsDesc,
];

const eventsSortOptions = [
  SortOptionId.EventsRelevance,
  SortOptionId.EventsStartDateDesc,
  SortOptionId.EventsStartDateAsc,
  SortOptionId.EventsMinPriceAsc,
  SortOptionId.EventsMaxPriceDesc,
];

const defaultSortOptions = [SortOptionId.Default];

function buildSortDropdownOptions(config) {
  return config.map(id => {
    return { id, valueKey: sortOptionConfig[id].translation };
  });
}

function getSortOptions(
  documentType?: SearchDocumentType,
): NotTranslatedDropdownOption[] {
  if (documentType === SearchDocumentType.Forums) {
    return buildSortDropdownOptions(forumSortOptions);
  }

  if (documentType === SearchDocumentType.Events) {
    return buildSortDropdownOptions(eventsSortOptions);
  }
  return buildSortDropdownOptions(defaultSortOptions);
}

function getSortRequest(sortOptionId: SortOptionId): ApiTypes.ISearchOrdering {
  return { ordering: sortOptionConfig[sortOptionId].sorters || [] };
}

function getDefaultOrdering(): ApiTypes.ISearchOrdering {
  return { ordering: [] };
}

function getDefaultSortOption(documentType?: SearchDocumentType) {
  if (documentType === SearchDocumentType.Forums) {
    return SortOptionId.ForumRelevance;
  }

  if (documentType === SearchDocumentType.Events) {
    return SortOptionId.EventsRelevance;
  }
  return SortOptionId.Default;
}

function isOrderingSupported(documentType?: SearchDocumentType) {
  if (!documentType) {
    return false;
  }
  return [SearchDocumentType.Events, SearchDocumentType.Forums].includes(
    documentType,
  );
}

export {
  getDefaultOrdering,
  getDefaultSortOption,
  getSortOptions,
  getSortRequest,
  isOrderingSupported,
};
