import { ISearchResponse, ISearchResponseTotals } from '@wix/client-search-sdk';
import {
  searchRequestStartedParams,
  searchRequestFinishedParams,
} from '@wix/bi-logger-wix-search-widget';
import { getBITotals } from './utils';

export type SearchRequestBi = {
  started: () => void;
  finished: (
    searchResponse: ISearchResponse,
    searchResponseTotals: ISearchResponseTotals,
  ) => void;
  failed: (error: Error) => void;
};

export type CreateSearchRequestBI = ({
  biLogger: Logger,
  isDemoContent: boolean,
  searchRequest,
  correlationId,
}) => SearchRequestBi;

export const createSearchRequestBI: CreateSearchRequestBI = ({
  biLogger,
  isDemoContent,
  searchRequest,
  correlationId,
}) => {
  let startTime: number;

  const biProps: searchRequestStartedParams | searchRequestFinishedParams = {
    isDemo: isDemoContent,
    target: searchRequest.query,
    documentType: searchRequest.documentType,
    correlationId,
    orderingDirection: searchRequest.ordering?.ordering?.[0]?.direction,
    orderingFieldName: searchRequest.ordering?.ordering?.[0]?.fieldName,
  };

  const started = () => {
    startTime = Date.now();
    void biLogger.searchRequestStarted(biProps);
  };

  const finished = (
    searchResponse: ISearchResponse,
    searchResponseTotals: ISearchResponseTotals,
  ) => {
    void biLogger.searchRequestFinished({
      ...biProps,
      success: true,
      loadingDuration: Date.now() - startTime,
      resultCount: searchResponse.totalResults,
      resultsArray: getBITotals(searchResponseTotals),
    });
  };

  const failed = (error: Error) => {
    void biLogger.searchRequestFinished({
      ...biProps,
      success: false,
      error: error.toString(),
      loadingDuration: Date.now() - startTime,
    });
  };

  return { started, finished, failed };
};
