export const enum TPAEditMode {
  EDITOR = 'editor',
  PREVIEW = 'preview',
}

export const enum TPADeviceType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

// NOTE: https://dev.wix.com/app/1484cb44-49cd-5b39-9681-75188ab429de/register-app
export const APP_DEFINITION_ID = '1484cb44-49cd-5b39-9681-75188ab429de';
export const WIDGET_ID = '44c66af6-4d25-485a-ad9d-385f5460deef';

// NOTE: window.__CI_APP_VERSION__ is replaced by yoshi with build version, so it's not problem to run it on nodejs or webworker
// TODO: review when https://github.com/wix/yoshi/pull/1496 resolved
export const ARTIFACT_VERSION = (window as any).__CI_APP_VERSION__;
