import {
  ISearchRequest,
  ClientSearchSDK,
  ISearchResponse,
  ISearchResponseTotals,
  ISampleResponse,
  SearchDocumentType,
  ISampleResponseSample,
} from '@wix/client-search-sdk';

import { ISearchLocation } from './location';
import { CategorySettings } from '@wix/search-results-settings-definitions';
import { SearchRequestBi } from './bi/createSearchRequestBI';
import { ISearchSample } from './SearchResultsControllerStore.types';

const SAMPLES_SIZE = {
  MOBILE: 4,
  DESKTOP: 3,
};

type CategoryEntry = [SearchDocumentType, CategorySettings];

const sortCategories = (
  visibleCategories: Array<[string, CategorySettings]>,
) => {
  const sortedCategories = visibleCategories
    .sort((c1: CategoryEntry, c2: CategoryEntry) => c1[1].index - c2[1].index)
    .map(c => c[0]);
  return sortedCategories;
};

const getSearchResponses = (
  searchRequest: ISearchRequest,
  shouldShowSamples: boolean,
  searchSDK: ClientSearchSDK,
  isMobile: boolean,
  previousQuery: string | undefined,
  searchResponseTotals: ISearchResponseTotals,
) => {
  const shouldLoadTotals = previousQuery !== searchRequest.query;

  const samplesPromise =
    shouldShowSamples || shouldLoadTotals
      ? searchSDK.getSample({
          query: searchRequest.query,
          limit: isMobile ? SAMPLES_SIZE.MOBILE : SAMPLES_SIZE.DESKTOP,
        })
      : Promise.resolve({ results: [] });

  return Promise.all<ISearchResponse, ISearchResponseTotals, ISampleResponse>([
    shouldShowSamples
      ? Promise.resolve({
          documents: [],
          documentType: SearchDocumentType.All,
          totalResults: 0,
        })
      : searchSDK.search(searchRequest),
    shouldLoadTotals
      ? samplesPromise.then((samplesResponse: ISampleResponse) => {
          const totalResults = samplesResponse.results.reduce(
            (sum, { total }) => sum + total,
            0,
          );
          return samplesResponse.results.reduce(
            (
              totals: ISearchResponseTotals,
              { documentType, total }: ISampleResponseSample,
            ) => {
              totals[documentType] = total;
              return totals;
            },
            {
              [SearchDocumentType.All]: totalResults,
            },
          );
        })
      : searchResponseTotals,
    samplesPromise,
  ]);
};

type SearchParams = {
  searchRequest: ISearchRequest;
  searchSDK: ClientSearchSDK;
  isMobile: boolean;
  previousQuery: string | undefined;
  previousTotals: ISearchResponseTotals;
  searchResultsAbsoluteUrl: string;
  searchLocation: ISearchLocation;
  visibleCategories: Array<[string, CategorySettings]>;
  bi: SearchRequestBi;
};

export const search = async ({
  searchRequest,
  searchSDK,
  isMobile,
  previousQuery,
  previousTotals,
  searchResultsAbsoluteUrl,
  searchLocation,
  visibleCategories,
  bi,
}: SearchParams): Promise<{
  searchResponse: ISearchResponse;
  searchResponseTotals: ISearchResponseTotals;
  searchSamples: ISearchSample[];
}> => {
  try {
    bi.started();

    const shouldShowSamples =
      searchRequest.documentType === SearchDocumentType.All;

    const sortedCategories = sortCategories(visibleCategories);

    const [
      searchResponse,
      searchResponseTotals,
      searchSampleResponse,
    ] = await getSearchResponses(
      searchRequest,
      shouldShowSamples,
      searchSDK,
      isMobile,
      previousQuery,
      previousTotals,
    );

    if (shouldShowSamples) {
      searchResponse.totalResults =
        searchResponseTotals[SearchDocumentType.All] || 0;
    }

    const searchSamples: ISearchSample[] = searchSampleResponse.results
      .map(s => ({
        ...s,
        url: searchLocation.buildSearchResultsUrl(searchResultsAbsoluteUrl, {
          query: searchRequest.query,
          documentType: s.documentType,
        }),
      }))
      .sort(
        (s1, s2) =>
          sortedCategories.indexOf(s1.documentType) -
          sortedCategories.indexOf(s2.documentType),
      );

    bi.finished(searchResponse, searchResponseTotals);
    return { searchResponse, searchResponseTotals, searchSamples };
  } catch (error) {
    bi.failed(error);
    throw error;
  }
};
