import {
  ILocationSearchRequest,
  IEncodedLocationSearchRequest,
} from '../location.types';

import { decoders } from './serializers';
import { DEFAULT_SEARCH_REQUEST } from './defaultSearchRequest';

export function decodeSearchRequest({
  q,
  qc,
  page,
  sort,
}: IEncodedLocationSearchRequest): ILocationSearchRequest {
  return {
    query: decodeURIComponent(
      decodeURIComponent(q || DEFAULT_SEARCH_REQUEST.query),
    ), // ¯\_(ツ)_/¯
    ...(qc && { documentType: decoders.decodeDocumentType(qc) }),
    ...{ page: decoders.decodePageNumber(page) },
    ...{ sortBy: decoders.decodeSortBy(sort) },
  };
}
