import {
  SearchDocumentType,
  ISearchRequestSorters,
  ISearchRequestPageNumber,
  ISearchRequest,
} from '@wix/client-search-sdk';

export enum SearchQueryParams {
  Query = 'q',
  DocumentType = 'qc',
  Page = 'page',
  SortBy = 'sort',
}

export interface ILocationSearchRequest {
  query: string;
  documentType?: SearchDocumentType;
  sortBy?: ISearchRequestSorters[];
  page?: ISearchRequestPageNumber;
}

export type IEncodedLocationSearchRequest = {
  [key in SearchQueryParams]?: string;
};

export type IDecodeSearchPath = (path: string) => ILocationSearchRequest;
export type IEncodeSearchPath = (
  searchRequest: ILocationSearchRequest,
) => string;

export interface ISearchLocation {
  decodePath: IDecodeSearchPath;
  encodePath: IEncodeSearchPath;

  navigateToSearchResults(searchRequest: ILocationSearchRequest): Promise<void>;

  getSearchResultsRelativeUrl(): Promise<string>;
  getSearchResultsAbsoluteUrl(): Promise<string>;

  toSDKSearchRequest(
    searchRequest: ILocationSearchRequest,
    pageSize: number,
  ): ISearchRequest;

  toLocationSearchRequest(
    searchRequest: ISearchRequest,
  ): ILocationSearchRequest;

  buildSearchResultsUrl(
    searchResultsAbsoluteUrl: string,
    locationSearchRequest: ILocationSearchRequest,
  ): string;
}
