import { SearchDocumentType } from '@wix/client-search-sdk';

const ENCODING_MAP: Record<SearchDocumentType, string> = {
  [SearchDocumentType.All]: 'all',
  [SearchDocumentType.Pages]: 'pages',
  [SearchDocumentType.Products]: 'products',
  [SearchDocumentType.Blogs]: 'blogs',
  [SearchDocumentType.Forums]: 'forums',
  [SearchDocumentType.Bookings]: 'bookings',
  [SearchDocumentType.Events]: 'events',
};

const DECODING_MAP = Object.keys(ENCODING_MAP).reduce(
  (acc, k: SearchDocumentType) => {
    acc[ENCODING_MAP[k]] = k;

    return acc;
  },
  {} as Record<string, SearchDocumentType>,
);

export function encodeDocumentType(documentType: SearchDocumentType): string {
  return ENCODING_MAP[documentType];
}

export function decodeDocumentType(raw: string): SearchDocumentType {
  return DECODING_MAP[raw];
}
