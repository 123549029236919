import { getSettingsValues } from '@wix/tpa-settings';
import {
  searchResultsSettings,
  CategoryList,
} from '@wix/search-results-settings-definitions';
import { IControllerConfig } from '@wix/native-components-infra/dist/src/types/types';

export const extractSettings = (
  config: IControllerConfig,
  categoryList: CategoryList,
) => {
  const componentData = config.publicData.COMPONENT || {};
  const componentSettings = getSettingsValues(
    componentData,
    searchResultsSettings,
  );

  const appData = config.publicData.APP || {};

  return {
    settings: { categoryList, ...componentSettings },
    publicData: { ...appData, ...componentData },
  };
};
